<script>
export default {
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    text() {
      return this.value?.text
    },
  },
}
</script>

<template>
  <div>
    {{ text }}
  </div>
</template>

<style scoped>
</style>
