<script>
import { EventBus } from "@/event-bus.js"

export default {
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      currentFile: null,
      type: null,
    }
  },
  computed: {
    placeholder() {
      return this.value.placeholder ? this.value.placeholder : "No file chosen"
    },
    message() {
      return this.value.message ? this.value.message : ""
    },
    tip() {
      return this.value.tip ? this.value.tip : ""
    },
    state() {
      return this.value.state ? this.value.state : null
    },
    readOnly() {
      return this.value.readOnly ? this.value.readOnly : false
    },
    disabled() {
      return this.value.disabled ? this.value.disabled : false
    },
    label() {
      return this.value.label ? this.value.label : ""
    },
    id() {
      return this.value.id ? this.value.id : Date.now().toString(36)
    },
    imageFileTypes() {
      return this.value.imageFileTypes ? this.value.imageFileTypes : ""
    },
    supportedFileTypes() {
      return this.value.supportedFileTypes ? this.value.supportedFileTypes : ""
    },
    downloadBaseUrl() {
      return this.value.downloadBaseUrl ? this.value.downloadBaseUrl : ""
    },
  },
  watch: {
    value: {
      handler(value) {
        this.$emit("input", value)
        this.showImages()
      },
      deep: true,
    },
  },
  beforeCreate() {
    EventBus.$on("autoDownloadAttachment", attachmentName => {
      if (attachmentName) {
        const attachment = this.value?.value?.filter(a => a.file.name === attachmentName)?.[0]
        if (attachment) {
          this.download(attachment)
          if (this.$route?.query?.attachment) {
            const query = Object.assign({}, this.$route.query)
            delete query.attachment
            this.$router.replace({query})
          }
        }
      }
    })
  },
  methods: {
    add() {
      if (this.currentFile) {
        const fileObject = {
          file: this.currentFile,
        }
        this.value.value.push(fileObject)
        this.clear()
        this.showImages()
      }
    },
    // remove(index) {
    //   if (this.value.value && this.value.value[index]) {
    //     this.value.value.splice(index, 1)
    //   }
    // },
    download(attachment) {
      const link = document.createElement("a")
      link.href = URL.createObjectURL(attachment.file)
      link.download = attachment.file.name
      document.body.append(link)
      link.click()
      link.remove()
    },
    clear() {
      this.currentFile = null
      this.type = null
    },
    showImages() {
      if (this.value.value && Array.isArray(this.value.value) && this.value.value.length > 0) {
        const promises = []
        for (let i = 0; i < this.value.value.length; i++) {
          if (!this.imageFileTypes.some(fileType => this.value.value[i].file.name.includes(fileType))) {
            continue
          }
          const filePromise = new Promise(resolve => {
            const reader = new FileReader()
            reader.onload = () => {
              resolve({
                id: `attachmentImage${i}`,
                image: reader.result,
              })
            }
            reader.readAsDataURL(this.value.value[i].file)
          })
          promises.push(filePromise)
        }
        Promise.all(promises).then(images => {
          images.forEach(image => {
            const element = document.getElementById(image.id)
            element.src = image.image
          })
        })
      }
    },
  },
}
</script>

<template>
  <div>
    <label v-if="label && label !== ''" :for="id" class="font-weight-bold">
      {{ label }}
    </label>
    <small class="d-block">
      Note: New attachments are not saved until this record is saved/updated.
    </small>
    <b-row class="mt-1">
      <b-col>
        <b-form-file
          v-model="currentFile"
          :accept="supportedFileTypes"
          :disabled="value.disabled"
          size="sm"
        />
      </b-col>
      <b-col cols="auto">
        <b-btn
          :disabled="value.disabled"
          size="sm"
          class="background-blue"
          squared
          @click="clear"
        >
          Clear
        </b-btn>
      </b-col>
      <b-col cols="auto">
        <b-btn
          size="sm"
          class="background-blue"
          :disabled="!currentFile"
          squared
          @click="add"
        >
          Add
        </b-btn>
      </b-col>
    </b-row>

    <b-row v-if="value.value.length === 0">
      <b-col class="text-center p-2">
        No attachments found.
      </b-col>
    </b-row>

    <b-row
      v-for="(attachment, index) in value.value"
      :key="index"
      class="background-light mt-3 ml-0 mr-0 p-3"
    >
      <b-col class="text-center pl-0">
        <b-img
          v-if="imageFileTypes.some(fileType => attachment.file.name.includes(fileType))"
          :id="`attachmentImage${index}`"
          src=""
          fluid
        />
        <div v-else>
          No preview available
        </div>
      </b-col>
      <b-col class="pl-0">
        {{ attachment.file.name }}
      </b-col>
      <b-col class="pl-0">
        {{ attachment.file.type }}
      </b-col>
      <b-col class="text-center p-0" cols="auto">
        <div
          v-b-tooltip.left="'Delete Coming Soon'"
          v-if="!disabled"
          class="delete pointer"
        >
          <b-icon icon="trash" />
        </div>
        <div
          v-b-tooltip.left="'Download'"
          v-if="attachment.uri && attachment.uri !== ''"
          class="download pointer"
          @click="download(attachment)"
        >
          <b-icon icon="download" />
        </div>
      </b-col>
    </b-row>
    <hr class="mt-0" />
  </div>
</template>

<style>
.b-custom-control-sm .custom-file-label,
.input-group-sm .custom-file-label {
  border-radius: 0 !important;
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  color: white;
  background-color: #194173 !important;
  border-radius: 0;
}

.delete, .download {
  font-size: 1.2em;
}
</style>
