import { mapGetters } from "vuex"
import moment from "moment"
import store from "@/store/index.js"
import Config from "../../config"

export default {
  data() {
    return {
      messageOptions: {
        size: "sm",
        buttonSize: "sm",
        footerClass: "p-2",
        centered: true,
        contentClass: "rounded-0",
      },
    }
  },
  computed: {
    ...mapGetters("global", ["getCampaignId"]),
  },
  methods: {
    showMsgBoxConfirm(message) {
      this.messageOptions.title = "Please Confirm"
      this.messageOptions.okTitle = "Proceed"
      return new Promise(resolve => {
        this.$bvModal.msgBoxConfirm(message, this.messageOptions).then(value => resolve(value))
      })
    },
    showMsgBoxOk(message) {
      this.messageOptions.title = "Notice"
      this.messageOptions.okTitle = "Ok"
      return new Promise(resolve => {
        this.$bvModal.msgBoxOk(message, this.messageOptions).then(value => resolve(value))
      })
    },
    epochToDate(epoch) {
      return moment.utc(epoch).format("YYYY-MM-DD HH:mm:ss")
    },
    formatCatalogEmail(form, reportId) {
      const catalogEmails = []

      if (form && form.email && Array.isArray(form.email) && form.email.length > 0) {
        form.email.forEach(email => {
          const catalogEmail = {}

          catalogEmail["emailId"] = email.emailId
          catalogEmail["reportId"] = reportId
          catalogEmail["personId"] = email.value

          catalogEmails.push(catalogEmail)
        })
      }

      return catalogEmails
    },
    formatCatalogComment(form, reportId) {
      const catalogComments = []

      if (form && form.history && Array.isArray(form.history) && form.history.length > 0) {
        form.history.forEach(history => {
          const catalogComment = {}

          catalogComment["commentId"] = history.commentId
          catalogComment["reportId"] = reportId
          catalogComment["personId"] = store.state.AuthService.personId
          catalogComment["commentDate"] = history.date
          catalogComment["commentText"] = history.description

          catalogComments.push(catalogComment)
        })
      }

      return catalogComments
    },
    formatCatalogAttachment(form, reportId) {
      const catalogAttachments = []

      if (form && form.attachment && Array.isArray(form.attachment) && form.attachment.length > 0) {
        form.attachment.forEach(attachment => {
          const catalogAttachment = {}

          catalogAttachment["reportId"] = reportId
          catalogAttachment["personId"] = store.state.AuthService.personId
          catalogAttachment["file"] = attachment.file
          catalogAttachment["exists"] = attachment.exists

          catalogAttachments.push(catalogAttachment)
        })
      }

      return catalogAttachments
    },
    viewInMaintenanceTools(report) {
      const message = "To view report contents, you will be redirected to the ARM Maintenance Tool."
      this.showMsgBoxConfirm(message).then(choice => {
        if (choice) {
          const reportId = report.reportId
          const reportFacilityCode = report.reportFacilityCode
          let url = Config.maintenance.maintenanceToolUrl
          switch (this.reportType) {
          case "pm":
            url += `/PM/View/${reportId}`
            break
          case "cm":
            url += `/CM/Reports/${this.getCampaignSiteCode}/${reportFacilityCode}/CMR/Get/${reportId}`
            break
          }
          const win = window.open(url, "_blank")
          win.focus()
        }
      })
    },
    createNewMaintenanceReport(reportType, siteCode) {
      const message = "To create a new report, you will be redirected to the ARM Maintenance Tool."
      this.showMsgBoxConfirm(message).then(choice => {
        if (choice) {
          const url = `${Config.maintenance.maintenanceToolUrl}/${reportType?.toUpperCase()}/Site/${siteCode}`
          const win = window.open(url, "_blank")
          win.focus()
        }
      })
    },
  },
}
