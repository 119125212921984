<script>
import Plot from "./Plot"

export default {
  name: "PlotResults",
  components: {
    Plot,
  },
  props: {
    type: { default: "", type: String },
    plots: {
      default: () => {
        return []
      },
      type: Array,
    },
  },
  data() {
    return {
      expand: false,
    }
  },
  computed: {
    plotsLess() {
      return this.plots ? this.plots.slice(0, 7) : null
    },
    plotsMore() {
      return this.plots ? this.plots.slice(7) : null
    },
  },
  watch: {
    plots() {
      this.expand = false
    },
  },
  methods: {
    expandedIndex(index) {
      return this.plotsLess ? this.plotsLess.length + index : -1
    },
  },
}
</script>

<template>
  <div id="plotResults">
    <div v-if="plots && plots.length > 0" :class="[plotsMore && plotsMore.length > 0 ? 'pb-5' : 'pb-3']">
      <b-row class="mt-3">
        <b-col cols="6" class="text-center">
          <p class="plot-type-label float-left">{{ type }}</p>
        </b-col>
        <b-col cols="6">
          <b-link
            v-if="plotsMore && plotsMore.length > 0 && expand"
            class="secondaryColor float-right font-weight-bold"
            @click="expand = false"
          >
            View Less...
          </b-link>
        </b-col>
      </b-row>
      <Plot :plots="plotsLess"/>

      <b-collapse v-model="expand">
        <Plot :plots="plotsMore" />
      </b-collapse>

      <b-link
        v-if="plotsMore && plotsMore.length > 0"
        class="secondaryColor font-weight-bold mt-2 float-right"
        @click="expand = !expand"
      >
        {{ this.expand ? "View Less..." : "View More..." }}
      </b-link>
    </div>
    <div v-else>
      No plots found.
    </div>
  </div>
</template>

<style scoped>
    .plot-type-label {
        font-weight: 700;
        font-size: 18px;
    }
  #plotResults {
    border-bottom: 1px solid hsla(0,0%,59.2%,.5);
  }
  #plotResults .divResult {
    /*padding-bottom: 20px;*/
  }
</style>
