<script>
import axios from "axios"
import Spinner from "@/components/Spinner"
import PredictionChart from "@/components/PredictionChart"
import Config from "/config"

export default {
  components: {
    PredictionChart,
    Spinner,
  },
  data() {
    return {
      response: null,
      loading: true,
      selected: "temp_mean",
      options: [
        { item: "temp_mean", name: "Temperature" },
        { item: "rh_mean", name: "Relative Humidity" },
        { item: "atmos_pressure", name: "Atmospheric Pressure" },
        { item: "vapor_pressure_mean", name: "Vapor Pressure" },
        { item: "wdir_vec_mean", name: "Wind Direction"},
        { item: "wspd_arith_mean", name: "Wind Speed"},
      ],
    }
  },
  mounted() {
    axios({
      method: "get",
      url: Config.anomalyDetectionApi.example,
      withCredentials: false,
    }).then((res) => {
      const { data } = res
      this.response = data
      this.loading = false
    })
  },
}
</script>

<template>
  <b-container class="py-3">
    
    <h1 class="font-weight-bold">
      Anomaly Detection
    </h1>
    <p>These plots show the predictive capabilities of deep learning models developed by Stephen Grady and Jitu Kumar. Each plot displays the prediction against the actual values of the selected variable for the met tower from  Southern Great Plains site E13. Future implementation could allow for dynamic predictions for additional datasets.</p>
    <div>
      <b-form-radio-group
        v-model="selected"
        :options="options"
        class="mb-3"
        value-field="item"
        text-field="name"
        disabled-field="notEnabled"
      />
      <div class="mt-3">
        Selected: <strong>{{ selected }}</strong>
      </div>
    </div>
    <b-container>
      <Spinner v-if="loading" text="Loading prediction chart..." />
      <div v-if="!loading">
        <PredictionChart :key="selected" :data="response[selected]" />
      </div>
    </b-container>
  </b-container>
</template>

<style scoped>
</style>
