<script>
import moment from "moment"

export default {
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      comment: null,
    }
  },
  computed: {
    placeholder() {
      return this.value.placeholder
        ? this.value.placeholder
        : "Enter comments here"
    },
    message() {
      return this.value.message ? this.value.message : ""
    },
    tip() {
      return this.value.tip ? this.value.tip : ""
    },
    state() {
      return this.value.state ? this.value.state : null
    },
    readOnly() {
      return this.value.readOnly ? this.value.readOnly : false
    },
    disabled() {
      return this.value.disabled ? this.value.disabled : false
    },
    label() {
      return this.value.label ? this.value.label : ""
    },
    id() {
      return this.value.id ? this.value.id : Date.now().toString(36)
    },
    user() {
      return this.value.user ? this.value.user : "User not found"
    },
    sortedHistory() {
      const sortedValue = this.value && this.value.value ?
        JSON.parse(JSON.stringify(this.value.value)) :
        []

      if (sortedValue && Array.isArray(sortedValue) && sortedValue.length > 0) {
        sortedValue.sort((a, b) => moment.utc(a.date).isAfter(moment.utc(b.date)) ? -1 : 1)
      }

      return sortedValue
    },
  },
  watch: {
    value: {
      handler(value) {
        this.$emit("input", value)
      },
      deep: true,
    },
  },
  methods: {
    postComment() {
      const payload = {
        user: this.user,
        type: "comment",
        icon: "chat-square-quote",
        date: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
        description: this.comment,
      }
      this.value.value.unshift(payload)
      this.comment = null
    },
    formatDate(date) {
      return moment.utc(date).format("YYYY-MM-DD HH:mm")
    },
  },
}
</script>

<template>
  <div>
    <label
      v-if="label && label !== ''"
      :for="id"
      class="font-weight-bold"
    >
      {{ label }}
    </label>
    <small class="d-block">
      NOTE: New comments are not saved until this record is saved/updated.
    </small>
    <b-form-textarea
      v-model="comment"
      :disabled="disabled"
      maxlength="500"
      size="sm"
      class="rounded-0"
      block
    />
    <b-btn
      :disabled="!comment || comment.length === 0"
      class="background-blue"
      size="sm"
      squared
      block
      @click="postComment"
    >
      Post
    </b-btn>

    <div :class="value.value.length > 0 ? 'history' : ''">
      <b-row v-if="value.value.length === 0">
        <b-col class="text-center p-2">
          No comments yet.
        </b-col>
      </b-row>

      <b-row
        v-for="(record, recordIndex) in sortedHistory"
        :key="recordIndex"
        :class="(recordIndex + 1) % 2 === 0 ? 'darkRow' : ''"
        class="py-1 mx-0 my-1"
      >
        <b-col
          class="text-center"
          cols="2"
        >
          <b-icon
            :icon="record.icon"
            font-scale="2"
          />
        </b-col>
        <b-col class="description" cols="6">
          <b>{{ record.user }}</b>
          <br />
          <div
            v-for="(line, lineIndex) in record.description.split('\n')"
            :key="lineIndex"
          >
            {{ line }}
          </div>
        </b-col>
        <b-col class="text-right" cols="4">
          {{ formatDate(record.date) }}
        </b-col>
      </b-row>
    </div>
    <hr class="mt-0" />
  </div>
</template>

<style scoped>
.history {
  max-height: 16em;
  overflow-x: hidden;
  overflow-y: scroll;
}

.darkRow {
  background-color: #e1e1e1;
}

.description {
  word-break: break-all;
}
</style>
