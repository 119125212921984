<script>
import Config from "/config"

export default {
  name: "Plot",
  props: {
    plots: {
      default: () => {
        return []
      },
      type: Array,
    },
  },
  data() {
    return {}
  },
  methods: {
    constructPlotsUrl(url) {
      return `${Config.plots.baseUrl}${url}`
    },
    constructVideoBaseUrl(url) {
      return `${Config.plots.videoThumbBaseUrl}${this.capitalizeSiteCode(`${url.substr(0, url.lastIndexOf("."))}.png`)}`
    },
    capitalizeSiteCode(url) {
      return url && url.substring(0, 3).toUpperCase() + url.slice(3)
    },
    handleImgError(evt) {
      evt.target.src = require("@/assets/arm-small-X-icon-01.svg")
      evt.target.alt = "Image not found"
    },
  },
}
</script>

<template>
  <b-row class="justify-content-md-center" no-gutters>
    <b-col
      v-for="plot in plots"
      :key="plot.node.urlEnd"
      class="plot-img-container mt-2 mr-1"
      cols="auto"
    >
      <b-card
        class="plot-card text-center"
        body-class="p-0"
        @click="$bvModal.show(`modal-${plot.node.id}`)"
      >
        <b-img
          v-if="plot.node.extension === 'mp4'"
          class="plot-img"
          :src="constructVideoBaseUrl(plot.node.urlEnd)"
          @error="handleImgError"
        />
        <b-img
          v-else
          class="plot-img"
          :src="constructPlotsUrl(plot.node.urlEnd)"
          @error="handleImgError"
        />
        <b-modal
          :id="`modal-${plot.node.id}`"
          hide-footer
          :title="`${plot.node.filenameDate}`"
          dialog-class="plotModal"
        >
          <video
            v-if="plot.node.extension === 'mp4'"
            :src="constructPlotsUrl(plot.node.urlEnd)"
            loop
            autoplay
          />
          <b-img v-else :src="constructPlotsUrl(plot.node.urlEnd)" />
        </b-modal>
        <b>{{ plot.node.filenameDate }}</b>
      </b-card>
    </b-col>
  </b-row>
</template>

<style>
.plot-img-container {
  min-height: 55px;
}

.plot-img {
  min-width: 200px;
  max-width: 200px;
  cursor: pointer;
  padding: 5px;
}

.plotModal {
  margin-bottom: 0px !important;
  max-width: 1024px !important;
}

.plot-card {
  min-width: 200px;
  max-width: 200px;
  padding: 0;
  border-radius: 0 !important;
  position: relative;
  cursor: pointer;
}

.plot-type-label {
  margin: 0 0 2px;
  font-weight: 700;
  font-size: 18px;
}
</style>
