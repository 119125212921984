<script>
import { mapGetters } from "vuex"
import Spinner from "@/components/Spinner"
import WeatherWidget from "@/components/WeatherWidget"

export default {
  components: {
    Spinner,
    WeatherWidget,
  },
  data() {
    return {
      campaignOverview: null,
      defaultReportType: {
        reportTypeId: null,
        reportTypeName: null,
        reportTypeDesc: null,
      },
    }
  },
  computed: {
    ...mapGetters("global", ["getCampaignAcronym", "getCampaignId"]),
    background() {
      let style = ""
      try {
        style = {
          "background-image": `url(${`https://adc.arm.gov/images/afcd/background_images/background_${this.getCampaignAcronym}.jpg`})`,
        }
      } catch (error) {
        console.error(`Warning: could not find background image for ${this.getCampaignAcronym}`)
      }
      return style
    },
  },
  mounted() {
    this.getCampaignOverview()
    this.getDefaultReportType()
  },
  methods: {
    getCampaignOverview() {
      this.$store.dispatch("global/getCampaignOverview", this.getCampaignId).then(response => {
        this.campaignOverview = response?.data?.data?.allCatalogCampaigns?.nodes?.[0]?.overview
      })
    },
    getDefaultReportType() {
      this.$store.dispatch("global/getReportTypes", this.getCampaignId).then(response => {
        const report = response?.data?.data?.allCatalogCampaignReportTypes?.edges?.[0]?.node?.catalogReportTypeByReportTypeId
        this.defaultReportType.reportTypeId = report?.reportTypeId
        this.defaultReportType.reportTypeName = report?.reportTypeName
        this.defaultReportType.reportTypeDesc = report?.reportTypeDesc
      })
    },
    newDefaultReport() {
      this.$router.push({
        path: `/${this.getCampaignAcronym}/report/${this.defaultReportType.reportTypeName}/new`,
      })
    },
    viewReports() {
      this.$router.push({
        path: `/${this.getCampaignAcronym}/reports`,
      })
    },
  },
}
</script>

<template>
  <div>
    <div
      class="overview"
      :style="background"
    />
    <b-container
      v-if="campaignOverview"
      class="pt-5"
    >
      <b-row class="text-center">
        <b-col cols="12">
          <WeatherWidget />
        </b-col>
      </b-row>
      <b-row class="text-center py-4">
        <b-col cols="9">
          <div
            class="foreground-white background-blue text-left px-3 py-2"
            v-html="campaignOverview"
          />
        </b-col>
        <b-col cols="3">
          <b-row>
            <b-col>
              <div
                class="foreground-white background-blue button tile pointer p-4"
                @click="newDefaultReport"
              >
                Submit
                <br />
                {{ defaultReportType.reportTypeName.charAt(0).toUpperCase() + defaultReportType.reportTypeName.slice(1) }}
                Report
              </div>
            </b-col>
          </b-row>
          <b-row class="pt-4">
            <b-col>
              <div
                class="foreground-white background-blue button pointer p-4"
                @click="viewReports"
              >
                View
                <br />
                Reports
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>

    <Spinner
      v-else
      text="Loading overview..."
    />

  </div>
</template>

<style scoped>
.overview {
  position: absolute;
  width: 100%;
  min-height: calc(100vh - 85px - 22px - 40px - 112px);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-filter: blur(6px);
  -ms-filter: blur(6px);
  filter: blur(6px);
}

a {
  color: white !important;
  text-decoration: underline !important;
}

.button {
  font-size: 1.6em;
  line-height: 1;
  height: 100% !important;
}
</style>
