import Config from "/config.js"
import axios from "axios"

const global = {
  namespaced: true,
  actions: {
    getMaintenanceReports: ({commit, state, rootState}, payload) => {
      return axios({
        method: "get",
        url: payload.reportType === "pm" ?
          Config.maintenance.pmApiSearchUrl :
          Config.maintenance.cmApiSearchUrl,
        params: {
          params: `reportSiteCode:${payload.siteCode}`,
          pageInt: payload.pageInt,
          pageSize: payload.pageSize,
          sort: payload.sortBy,
          sortDir: payload.sortDir,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        withCredentials: true,
      })
    },
  },
}

export default global
