<script>
export default {
  props: {
    value: { type: Number, required: true },
    pageSize: { default: 10, type: Number },
    totalRows: { default: 0, type: Number },
  },
  data: () => ({
    pageNumber: 1,
    pageSizeOptions: [
      10,
      25,
      50,
      100,
    ],
  }),
  watch: {
    pageNumber(val) {
      this.$emit("input", val)
    },
  },
}
</script>

<template>
  <b-row>
    <b-col>
      Page Size:
      <b-dropdown
        :text="pageSize.toString()"
        variant="outline-secondary"
        class="page-size ml-1"
        size="sm"
        dropup
      >
        <b-dropdown-item
          v-for="(size, index) in pageSizeOptions"
          :key="index"
          @click="$emit('updatePageSize', size)"
        >
          {{ size }}
        </b-dropdown-item>
      </b-dropdown>
    </b-col>
    <b-col>
      <b-pagination
        v-model="pageNumber"
        :total-rows="totalRows"
        :per-page="pageSize"
        align="center"
        size="sm"
        pills
      />
    </b-col>
    <b-col class="text-right">
      {{ totalRows }}
      report{{ totalRows === 1 ? "" : "s" }}
      found
    </b-col>
  </b-row>
</template>

<style scoped>
::v-deep .page-link {
  color: #194173;
}

::v-deep .page-item.active .page-link {
  background-color: #00bd70;
  border: 1px solid #00bd70;
}

::v-deep .page-size > * {
  border-radius: 0;
  margin: 0;
}
</style>
