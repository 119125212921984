<script>
export default {
  data: () => ({
    time: null,
  }),
  beforeMount() {
    setInterval(() => {
      this.time = this.$moment.utc().format("YYYY-MM-DD HH:mm:ss")
    }, 1000)
  },
}
</script>

<template>
  <b-row class="stats m-0">
    <b-col>
      <div class="time float-right">
        Current time:
        <span class="time-value">
          {{ time }}
        </span>
        UTC
      </div>
    </b-col>
  </b-row>
</template>

<style scoped>
.stats {
  height: 22px;
}

.time {
  font-size: 0.9em;
  pointer-events: none;
}

.time-value {
  font-family: monospace;
}
</style>
