<script>
import Form from "@/components/Form"

export default {
  components: {
    Form,
  },
  props: {
    chartFields: { type: Array, required: true },
  },
  data: () => ({
    fields: [],
  }),
  mounted() {
    this.fields = this.chartFields
  },
  methods: {
    viewChart() {
      const params = this.fields?.[0]?.items?.map(item => {
        return {
          id: item.data.id,
          value: item.data.value,
        }
      })
      this.$emit("getChartParams", params)
    },
  },
}
</script>

<template>
  <div>
    <Form
      v-if="fields && fields.length > 0"
      v-model="fields"
    />
    <b-btn
      class="background-blue float-right"
      size="sm"
      squared
      @click="viewChart"
    >
      View Chart
    </b-btn>
  </div>
</template>

<style>
</style>
