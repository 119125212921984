<script>
export default {
  props: {
    emit: { default: false, type: Boolean },
  },
  methods: {
    goBack() {
      if (this.emit) {
        this.$emit("goBack")
      } else {
        this.$router.go(-1)
      }
    },
  },
}
</script>

<template>
  <b-container class="back">
    <b-btn
      class="foreground-blue pl-0"
      variant="link"
      squared
      @click="goBack"
    >
      <b-icon icon="arrow-left" />
    </b-btn>
  </b-container>
</template>

<style>
.back {
  height: 40px;
}
</style>
