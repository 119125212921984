<script>
export default {
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    state() {
      return this.value.state ? this.value.state : null
    },
    readOnly() {
      return this.value.readOnly ? this.value.readOnly : false
    },
    disabled() {
      return this.value.disabled ? this.value.disabled : false
    },
    label() {
      return this.value.label ? this.value.label : ""
    },
    id() {
      return this.value.id ? this.value.id : Date.now().toString(36)
    },
    note() {
      return this.value?.note
    },
    options() {
      return this.value?.options
    },
  },
}
</script>

<template>
  <div :id="id">
    <label
      v-if="label && label !== ''"
      :for="id"
      class="font-weight-bold"
    >
      {{ label }}
    </label>
    <div v-if="note">
      {{ note }}
    </div>
    <b-row class="mt-1">
      <b-col>
        <b-form-group>
          <b-form-checkbox-group
            v-model="value.value"
            :name="id"
            :options="options"
            :disabled="disabled"
            stacked
          />
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
</style>
