<template>
  <div class="example">
    <apexcharts
      width="100%"
      height="400"
      type="line"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts"

export default {
  name: "PredictionChart",
  components: {
    apexcharts: VueApexCharts,
  },
  props: {
    data: {type: Object, required: true},
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: "basic-line",
        },
        xaxis: {
          categories: this.data.labels,
          text: this.data.info,
        },
        yaxis: {
          title: {
            text: this.data.info,
          },
        },
        legend: {
          position: "top",
          horizontalAlign: "center",
        },
      },
      series: [
        {
          name: "Actual",
          data: this.data.actual,
        },
        {
          name: "Prediction",
          data:  this.data.predictions ,
        },
      ],
    }
  },
}
</script>
