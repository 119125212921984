<script>
import Vue from "vue"
import Spinner from "./Spinner"
import { mapState, mapGetters } from "vuex"
import "vue-material/dist/vue-material.min.css"
import "vue-material/dist/theme/default.css"
import { MdDatepicker, MdButton, MdDialog } from "vue-material/dist/components"
Vue.use(MdButton)
Vue.use(MdDialog)
Vue.use(MdDatepicker)

export default {
  name: "PlotFilter",
  components: {
    Spinner,
  },
  data() {
    return {
      instrumentClass: null,
      facility: null,
      dataLevel: null,
      plotType: null,
      startDate: null,
      endDate: null,
      defaultDates: [],
      loadingPlotOptions: false,
    }
  },
  computed: {
    ...mapState("plots", ["plots", "instrumentClasses", "plotOptions"]),
    ...mapGetters("global", ["getCampaignAcronym", "getCampaignSiteCode"]),
    getInstClasses() {
      return [
        ...new Set(this.instrumentClasses.map(val => val.keys[0].toUpperCase()).sort()),
      ]
    },
    getFacilities() {
      // return [...new Set(this.plotOptions.facilityCode.map(val => val.keys[0]).sort())]
      const options = []
      for (const el of this.plotOptions.facilityCode) {
        options.push({value: el.keys[0], text: `${el.keys[0]} - ${this.plotOptions.facilityName[el.keys[0]]}`})
      }
      return options
    },
    getDataLevels() {
      return [...new Set(this.plotOptions.dataLevelCode.map(val => val.keys[0]))]
    },
    getPlotTypes() {
      return [...new Set(this.plotOptions.plotName.map(val => val.keys[0]))]
    },
    getDates() {
      return [...new Set(this.plotOptions.filenameDate.map(val => val.keys[0]))]
    },
    validateDefaultDates() {
      return this.defaultDates && this.defaultDates.length ===2 && this.startDate === this.defaultDates[0] && this.endDate === this.defaultDates[1] ? true : false
    },
    loading() {
      return this.getInstClasses?.length === 0
    },
    loadingDates() {
      return this.defaultDates?.length === 0
    },
  },
  watch: {
    getDates() {
      if (this.getDates && this.getDates.length > 0) {
        const moments = this.getDates.map(d => this.$moment(d))
        this.endDate = `${this.$moment
          .max(moments)
          .format("YYYY-MM-DD")}`
        this.startDate = `${this.$moment(this.endDate).clone().subtract(6, "days").format("YYYY-MM-DD")}`
        this.defaultDates.push(this.startDate, this.endDate)
      }
    },
    getFacilities() {
      this.loadingPlotOptions = this.getFacilities?.length === 0
    },
    getDataLevels() {
      this.loadingPlotOptions = this.getDataLevels?.length === 0
    },
    getPlotTypes() {
      this.loadingPlotOptions = this.getPlotTypes?.length === 0
    },
    loadingDates() {
      this.loadingPlotOptions = this.loadingDates?.length === 0
    },
  },
  created() {
    this.$store.dispatch("plots/retrieveInstClasses", {siteCode:this.getCampaignSiteCode, campaignAcronym:this.getCampaignAcronym})
  },
  methods: {
    retrieveOptions() {
      this.facility = ""
      this.dataLevel = ""
      this.plotType = ""
      this.$store.dispatch("plots/retrievePlotOptions", {
        siteCode: this.getCampaignSiteCode,
        instrumentClass: this.instrumentClass.toLowerCase(),
      })
    },
    getPlots() {
      const payload = {
        siteCode: this.getCampaignSiteCode,
        instrumentClass: this.instrumentClass.toLowerCase(),
        facility:
          this.facility !== null && this.facility === ""
            ? `[${this.getFacilities.map(val => `"${val.value}"`).join(",")}]`
            : `"${this.facility}"`,
        dataLevelCode:
          this.dataLevel !== null && this.dataLevel === ""
            ? `[${this.getDataLevels.map(val => `"${val}"`).join(",")}]`
            : `"${this.dataLevel}"`,
        plotName:
          this.plotType !== null && this.plotType === ""
            ? `[${this.getPlotTypes.map(val => `"${val}"`).join(",")}]`
            : `"${this.plotType}"`,
        startDate: this.$moment(this.startDate).format("YYYYMMDD"),
        endDate: this.$moment(this.endDate).format("YYYYMMDD"),
      }
      this.$store.dispatch("plots/retrievePlots", payload)
    },
    minDateDisabled(curDay) {
      return this.endDate ? this.$moment(curDay).format("YYYY-MM-DD") >= this.$moment(this.endDate).format("YYYY-MM-DD") : false
    },
    maxDateDisabled(curDay) {
      return this.startDate ? this.$moment(curDay).format("YYYY-MM-DD") <= this.$moment(this.startDate).format("YYYY-MM-DD") : false
    },

  },
}
</script>

<template>
  <div>
    <Spinner
      v-if="loading"
      text="Loading plot filters..."
    />
    <div v-else>
      <h2 class="font-weight-bold pb-2">
        {{ getCampaignAcronym.toUpperCase() }} Data Plots
      </h2>
      <b-row>
        <b-col cols="2">
          <label class="font-weight-bold">
            Instrument Class
          </label>
          <b-form-select
            v-model="instrumentClass"
            :options="getInstClasses"
            @change="retrieveOptions"
          >
            <template #first>
              <b-form-select-option
                :value="null"
                disabled
              >
                Select Class
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <label class="font-weight-bold">
            Facility
          </label>
          <b-form-select
            v-model="facility"
            :options="getFacilities"
            :disabled="!instrumentClass"
          >
            <template #first>
              <b-form-select-option value="">
                Facility: All
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <label class="font-weight-bold">
            Data Level
          </label>
          <b-form-select
            v-model="dataLevel"
            :options="getDataLevels"
            :disabled="!instrumentClass"
          >
            <template #first>
              <b-form-select-option value="">
                Data Level: All
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <label class="font-weight-bold">
            Plot Type
          </label>
          <b-form-select
            v-model="plotType"
            :options="getPlotTypes"
            :disabled="!instrumentClass"
          >
            <template #first>
              <b-form-select-option value="">
                Plot Type: All
              </b-form-select-option>
            </template>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <label class="font-weight-bold">
            Start Date
          </label>
          <b-form-input
            v-if="!instrumentClass"
            disabled
          />
          <b-form-input
            v-else-if="instrumentClass && loadingDates"
            placeholder="Loading..."
            disabled
          />
          <md-datepicker
            v-else
            v-model="startDate"
            md-immediately
            :md-disabled-dates="minDateDisabled"
          />
          <small
            v-if="validateDefaultDates"
            class="text-info"
            style="font-weight: bold"
          >
            Last available 7 days date range
          </small>
        </b-col>
        <b-col cols="2">
          <label class="font-weight-bold">
            End Date
          </label>
          <b-form-input
            v-if="!instrumentClass"
            disabled
          />
          <b-form-input
            v-else-if="instrumentClass && loadingDates"
            placeholder="Loading..."
            disabled
          />
          <md-datepicker
            v-else
            v-model="endDate"
            md-immediately
            :md-disabled-dates="maxDateDisabled"
          />
        </b-col>
      </b-row>
      <b-row v-show="loadingPlotOptions" class="justify-content-md-center mt-3">
        <Spinner v-if="loadingPlotOptions"/>
      </b-row>
      <b-row
        v-show="instrumentClass"
        class="justify-content-md-center mt-3"
        no-gutters
      >
        <b-col cols="4">
          <b-button
            id="plotBtn"
            class="background-blue"
            block
            squared
            @click="getPlots"
          >
            Get Plots
          </b-button>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style>
/********************************************
  Datepicker Overrides
********************************************/
div.md-field.md-datepicker {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px;
    background-color: #fff;
    height: calc(1.5em + .75rem + 2px);
    min-height: 0px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
}

div.md-field.md-datepicker label {
    margin-top: -16px;
}

div.md-field.md-datepicker.md-focused label {
    color: #fff;
}

div.md-field.md-datepicker.md-has-value label {
    color: #fff;
}

div.md-field.md-datepicker:focus label {
    color: #fff;
}

div.md-field.md-datepicker > i::after {
    display: none;
}

div.md-field.md-datepicker > .md-button {
    margin-top: -16px;
}

div.md-datepicker-header {
    background-color: black !important;
}

.md-datepicker-dialog * {
    font-family: "Proxima Nova" !important;
    font-weight: 100 !important;
}

.md-datepicker-selected {
    background-color: #194173 !important;
}

.md-field.md-theme-default:before {
    background-color: #ced4da !important;
}

.md-datepicker-header {
    display: none;
}

.md-field.md-theme-default:after {
    background-color: transparent !important;
}

.md-field>.md-icon {
    margin-left: 4px;
    margin-top: 6px;
}

div.md-field {
    margin: 0 !important;
}
</style>
