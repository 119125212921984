<script>
import CKEditor from "ckeditor4-vue"

export default {
  components: {
    ckeditor: CKEditor.component,
  },
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      editorConfig: {},
    }
  },
  computed: {
    readOnly() {
      return this.value.readOnly ? this.value.readOnly : false
    },
    notes() {
      return this.value?.notes
    },
  },
}
</script>

<template>
  <div>
    <label v-if="value.label" :for="value.id" class="font-weight-bold">
      {{ value.label }}
    </label>
    <div v-if="notes">
      {{ notes }}
    </div>
    <ckeditor
      :id="value.id"
      v-model="value.value"
      :config="editorConfig"
      :read-only="readOnly"
    />
  </div>
</template>

<style scoped></style>
