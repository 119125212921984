<script>
import store from "@/store/index.js"
import global from "@/mixins/global.js"
import { mapGetters } from "vuex"
import Back from "@/components/Back"
import ResultsContainer from "@/components/ResultsContainer"
import Paginator from "@/components/Paginator"
import MaintenanceReportModal from "@/components/MaintenanceReportModal"
import Spinner from "@/components/Spinner"

export default {
  components: {
    Back,
    ResultsContainer,
    Paginator,
    MaintenanceReportModal,
    Spinner,
  },
  mixins: [
    global,
  ],
  data: () => ({
    loading: false,
    maintenanceReports: [],
    sortBy: "reportId",
    sortDir: "DESC",
    pageNumber: 1,
    pageSize: 10,
    totalRows: 0,
    selectedMaintenanceReport: {},
    maintenanceReportFields: [
      { key: "reportId", label: "Report ID", sortable: true },
      { key: "reportedOn", label: "Reported On", sortable: true },
      { key: "reporter", label: "Reporter" },
      { key: "facilityCode", label: "Facility", sortable: true },
      { key: "instrument", label: "Instrument", sortable: true },
      { key: "action", label: "Action" },
    ],
  }),
  computed: {
    ...mapGetters("global", [
      "getCampaignAcronym",
      "getCampaignSiteCode",
      "getFieldSortMap",
    ]),
    reportType() {
      return this.$route.params.reportType?.toString()
    },
  },
  watch: {
    pageNumber() {
      this.getMaintenanceReports()
    },
    pageSize() {
      this.getMaintenanceReports()
    },
  },
  mounted() {
    this.getMaintenanceReports()
  },
  methods: {
    getMaintenanceReports() {
      this.loading = true
      const payload = {
        reportType: this.reportType,
        siteCode: this.getCampaignSiteCode,
        pageInt: this.pageNumber - 1,
        pageSize: this.pageSize,
        sortBy: this.sortBy,
        sortDir: this.sortDir,
      }
      store.dispatch("maintenance/getMaintenanceReports", payload).then(response => {
        this.maintenanceReports = response?.data?.content?.map(report => {
          const row = {
            key: report.reportId,
            reportId: report.reportId,
            reportedOn: this.epochToDate(report.reportedOn),
            // TODO - use string template format whenever the linter is fixed
            reporter: report.reporter.nameFirst + " " + report.reporter.nameLast,
            facilityCode: report.reportFacilityCode,
            reportData: report,
            action: [
              {
                key: "previewReport",
                label: "Preview Report",
              },
              {
                key: "viewReport",
                label: "View In Maintenance Tools",
              },
            ],
          }

          // Handle unique fields depending on report type
          if (this.reportType === "pm") {
            row.instrument = report?.reportInstrumentClass?.toUpperCase()
          } else if (this.reportType === "cm") {
            row.instrument = report?.instrumentCode?.toUpperCase()
          }

          return row
        })

        this.totalRows = response?.data?.totalElements
      }).finally(() => {
        this.loading = false
      })
    },
    previewReport(reportId) {
      this.selectedMaintenanceReport = this.maintenanceReports
        ?.filter(maintenanceReport => maintenanceReport.reportId === reportId)
        ?.[0]
        ?.reportData
      this.$bvModal.show("maintenance-preview-modal")
    },
    viewReport(reportId) {
      const report = this.maintenanceReports
        ?.filter(maintenanceReport => maintenanceReport.reportId === reportId)
        ?.[0]
        ?.reportData
      this.viewInMaintenanceTools(report)
    },
    createNewReport() {
      this.createNewMaintenanceReport(this.reportType, this.getCampaignSiteCode)
    },
    sortChanged(sort) {
      if (sort?.sortBy) {
        this.sortBy = this.getFieldSortMap[this.reportType][sort.sortBy]
        this.sortDir = sort.sortDesc ? "DESC" : "ASC"
        this.getMaintenanceReports()
      }
    },
  },
}
</script>

<template>
  <div>
    <Back class="back" />
    <b-container>
      <b-row>
        <b-col>
          <h1 class="font-weight-bold">
            {{ reportType ? reportType.toUpperCase() : "" }}
            Reports for
            {{ getCampaignAcronym ? getCampaignAcronym.toUpperCase() : "" }}
          </h1>
        </b-col>
        <b-col>
          <b-btn
            class="background-blue float-right"
            squared
            @click="createNewReport"
          >
            Create new
            {{ reportType.toUpperCase() }}
            Report for
            {{ getCampaignSiteCode.toUpperCase() }}
          </b-btn>
        </b-col>
      </b-row>
      <template v-if="maintenanceReports.length > 0">
        <ResultsContainer
          :rows="maintenanceReports"
          :fields="maintenanceReportFields"
          hide-totals
          @sortChanged="sortChanged"
          @previewReport="previewReport"
          @viewReport="viewReport"
        />
        <Paginator
          v-model="pageNumber"
          :page-size="pageSize"
          :total-rows="totalRows"
          @updatePageSize="pageSize = $event"
        />
      </template>
      <Spinner
        v-else-if="loading"
        text="Loading reports..."
      />
      <b-card
        v-else
        class="primary-background-light border-0 text-center mt-2"
      >
        <b-card-title class="no-results">
          No
          {{ reportType.toUpperCase() }}
          reports found for
          {{ getCampaignSiteCode.toUpperCase() }}.
        </b-card-title>
      </b-card>

      <MaintenanceReportModal :report-data="selectedMaintenanceReport" />
    </b-container>
  </div>
</template>

<style scoped>
</style>
