import LocalConfig from "@/config/config_local.js"
import DevConfig from "@/config/config_dev.js"
import StageConfig from "@/config/config_stage.js"
import ProdConfig from "@/config/config_prod.js"

let Config = LocalConfig

const appEnvironmentName = "APP_ENVIRONMENT_NAME_REPLACE"

if (appEnvironmentName == "dev") {
  Config = DevConfig 
} else if (appEnvironmentName == "stage") {
  Config = StageConfig 
} else if (appEnvironmentName == "prod") {
  Config = ProdConfig 
}

export default Config
