<script>
import axios from "axios"
import Multiselect from "vue-multiselect"

export default {
  components: {
    Multiselect,
  },
  props: {
    value: { type: Object, required: true },
  },
  data() {
    return {
      localValue: [],
      choices: [],
    }
  },
  computed: {
    placeholder() {
      return this.value.placeholder
        ? this.value.placeholder
        : "Enter comments here"
    },
    message() {
      return this.value.message ? this.value.message : ""
    },
    tip() {
      return this.value.tip ? this.value.tip : ""
    },
    state() {
      return this.value.state ? this.value.state : null
    },
    readOnly() {
      return this.value.readOnly ? this.value.readOnly : false
    },
    disabled() {
      return this.value.disabled ? this.value.disabled : false
    },
    label() {
      return this.value.label ? this.value.label : ""
    },
    id() {
      return this.value.id ? this.value.id : Date.now().toString(36)
    },
    user() {
      return this.value.user ? this.value.user : "User not found"
    },
    options() {
      return this.value.options ? this.value.options : []
    },
    sourceUrl() {
      return this.value.sourceUrl ? this.value.sourceUrl : ""
    },
    threshold() {
      return this.value.threshold ? this.value.threshold : 3
    },
  },
  watch: {
    value: {
      handler(value) {
        this.$emit("input", value)
      },
      deep: true,
    },
    /*
    value: {
      handler(newVal, oldVal) {
        const valueString = JSON.stringify(this.localValue.map(value => value.value))
        if (newVal && newVal != oldVal && newVal !== valueString) {
          this.getSelectionArray(newVal)
        }
      },
      deep: true,
    },
     */
  },
  created() {
    this.getChoices("")
  },
  methods: {
    getChoices(query) {
      if (query.length < this.threshold) {
        return
      }

      this.$store.dispatch("global/searchPeople", query).then(response => {
        this.choices = response.data.data.allPeople.nodes.map(result => {
          return {
            label: `${result.nameFirst} ${result.nameLast} (${result.email})`,
            value: result.personId,
          }
        })
      })
    },
    getSelectionArray(selection) {
      this.localValue = []
      const emails = selection && !Array.isArray(selection) ?
        JSON.stringify(selection.split(",")) :
        selection
      JSON.parse(emails).forEach(email => {
        const url = `${this.$store.state.peopleApiUrl}person/search`
        const config = {
          params: {
            email,
          },
        }
        axios
          .get(url, config)
          .then(response => {
            const person = response.data[0]
            if (person) {
              this.localValue.push({
                label: `${person.nameFirst} ${person.nameLast} (${person.email})`,
                value: person.email,
              })
            } else {
              this.localValue.push({
                label: email,
                value: email,
              })
            }
          })
      })
    },
    addEmail(email) {
      const tag = {
        label: email,
        value: email,
      }
      this.localValue.push(tag)
      this.choices.push(tag)
    },
  },
}
</script>

<template>
  <div>
    <label
      v-if="label && label !== ''"
      :for="id"
      class="font-weight-bold"
    >
      {{ label }}
    </label>
    <Multiselect
      v-model="value.value"
      :options="choices"
      :disabled="value.disabled"
      label="label"
      track-by="value"
      placeholder=""
      searchable
      multiple
      taggable
      @search-change="getChoices"
      @tag="addEmail"
    />
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />
<style>
.multiselect--disabled .multiselect__select {
  background-color: white;
}

.multiselect__content-wrapper {
  font-size: 0.8em;
}

.multiselect__tags {
  border-radius: 0;
  border-color: #ced4da;
}

.multiselect__content-wrapper,
.multiselect--above .multiselect__content-wrapper {
  border-radius: 0;
}

.multiselect__option--highlight,
.multiselect__option--highlight::after {
  color: white;
  background-color: #194173;
}

.multiselect__option--highlight.multiselect__option--selected,
.multiselect__option--highlight.multiselect__option--selected::after {
  color: white;
  background-color: tomato;
}

.multiselect__tag {
  background-color: #194173;
  border-radius: 50px;
}

.multiselect__tag-icon {
  border-radius: 0;
}

.multiselect__tag-icon:hover {
  background-color: tomato;
}

.multiselect__tag-icon::after {
  color: white;
}
</style>
