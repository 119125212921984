<script>
import { mapGetters } from "vuex"

export default {
  data: () => ({
    contacts: [],
    expandedIndexes: [],
    contactsCutoff: 9,
    contactsFormatting: [
      {
        key: "PI Contact",
        label: "Primary Investigator",
      },
      {
        key: "CoI Contact",
        label: "Co-Investigator",
      },
      {
        key: "Site Manager",
        label: "Site Manager",
      },
      {
        key: "Site Tech",
        label: "Site Tech",
      },
    ],
  }),
  computed: {
    ...mapGetters("global", ["getCampaignId", "getCampaignAcronym"]),
  },
  mounted() {
    this.$store.dispatch("global/getContacts", this.getCampaignId).then(response => {
      const contactGroups = []
      const results = response?.data?.data?.allCatalogContacts?.nodes
      if (results && Array.isArray(results) && results.length > 0) {
        const contactTypes = []
        results.forEach(contact => {
          if (!contactTypes.some(contactType => contactType.contactType === contact.contactType)) {
            const contactType = {
              contactType: contact.contactType,
              contactOrder: contact.contactOrder,
            }
            contactTypes.push(contactType)
          }
        })

        contactTypes.forEach(contactType => {
          const contactTypeLabel = this.contactsFormatting.
            filter(contactFormat => contactFormat.key === contactType.contactType)?.[0]?.label
          const contacts = results
            .filter(contact => contact.contactType === contactType.contactType)
            .sort((a, b) => a.nameLast < b.nameLast ? -1 : 1)
          const contactGroup = {
            contactType: contactType.contactType,
            contactTypeLabel,
            contactOrder: contactType.contactOrder,
            contacts,
          }
          contactGroups.push(contactGroup)
        })
      }

      contactGroups.sort((a, b) => a.contactOrder < b.contactOrder ? -1 : 1)
      this.contacts = contactGroups
    })
  },
  methods: {
    getContactGroupLabel(contactGroup) {
      const label = this.contactsFormatting
        .filter(contactFormat => contactFormat.key === contactGroup.contactType)?.[0]?.label
      return contactGroup?.contacts?.length > 1 ?
        `${label}s` :
        label
    },
    getContactList(index) {
      return this.expandedIndexes.includes(index) ?
        this.contacts[index].contacts :
        this.contacts[index].contacts.slice(0, this.contactsCutoff)
    },
    toggleExpand(index) {
      if (this.expandedIndexes.includes(index)) {
        const indexToRemove = this.expandedIndexes.indexOf(index)
        if (indexToRemove > -1) {
          this.expandedIndexes.splice(indexToRemove, 1)
        }
      } else {
        this.expandedIndexes.push(index)
      }
    },
  },
}
</script>

<template>
  <b-container class="py-3">
    <h1 class="font-weight-bold">
      {{ getCampaignAcronym.toUpperCase() }} Contacts
    </h1>

    <div
      v-for="(contactGroup, contactGroupIndex) in contacts"
      :key="contactGroup.contactOrder"
    >
      <h2 class="mt-4">
        {{ getContactGroupLabel(contactGroup) }}
      </h2>
      <b-row no-gutters>
        <b-col
          v-for="(contact, contactIndex) in getContactList(contactGroupIndex)"
          :key="contactIndex"
          cols="4"
        >
          <div class="foreground-blue background-grey p-2 m-2">
            <div class="font-weight-bold">
              {{ contact.nameFirst }}
              {{ contact.nameLast }}
            </div>
            <a
              :href="`mailto:${contact.email}`"
              class="contactLink"
            >
              {{ contact.email }}
            </a>
          </div>
        </b-col>
      </b-row>

      <div
        v-if="contactGroup.contacts.length > contactsCutoff"
        class="text-center"
      >
        <b-btn
          class="background-blue"
          size="sm"
          squared
          @click="toggleExpand(contactGroupIndex)"
        >
          {{ expandedIndexes.includes(contactGroupIndex) ? 'Show less' : 'Show more' }}
        </b-btn>
      </div>
    </div>

  </b-container>
</template>

<style scoped>
a {
  color: #194173 !important;
}
</style>
