<script>
import SelectList from "./SelectList"
import DatePicker from "./DatePicker"
import Editor from "./Editor"
import Attachment from "./Attachment"
import History from "./History"
import EmailSelector from "./EmailSelector"
import FormImage from "./FormImage"
import Checkboxes from "./Checkboxes"
import Note from "./Note"

export default {
  components: {
    SelectList,
    DatePicker,
    Editor,
    Attachment,
    History,
    EmailSelector,
    FormImage,
    Checkboxes,
    Note,
  },
  props: {
    value: { type: Array, required: true },
  },
}
</script>

<template>
  <div>
    <b-row
      v-for="(row, index) in value"
      :key="index"
    >
      <b-col
        v-for="col in row.items"
        :key="col.data.id"
        class="mb-4"
        :cols="col.cols"
      >
        <component
          :is="col.type"
          v-if="!col.hidden"
          v-model="col.data"
        />
      </b-col>
    </b-row>
  </div>
</template>

<style>
.form-control {
  border-radius: 0 !important;
}
</style>
