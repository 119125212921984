import Vue from "vue"
import Vuex from "vuex"

// Persistant Store
import createPersistedState from "vuex-persistedstate"

Vue.use(Vuex)

import App from "../../node_modules/@datatools/vue2-components-lib/src/store/App"
import AuthService from "../../node_modules/@datatools/vue2-components-lib/src/store/AuthService"
import GraphQL from "../../node_modules/@datatools/vue2-components-lib/src/store/GraphQL"
import SessionManager from "../../node_modules/@datatools/vue2-components-lib/src/store/SessionManager"

import global from "./modules/global"
import maintenance from "./modules/maintenance"
import plots from "./modules/plots"

const persistState = createPersistedState({
  paths: ["GraphQL", "AuthService", "SessionManager"],
})

export default new Vuex.Store({
  modules: {
    App,
    AuthService,
    GraphQL,
    global,
    maintenance,
    plots,
    SessionManager,
  },
  plugins: [persistState],
})
