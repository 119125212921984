<script>
export default {
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    id() {
      return this.value.id ? this.value.id : Date.now().toString(36)
    },
    label() {
      return this.value.label ? this.value.label : ""
    },
  },
  mounted() {
    if (this.value?.options?.[0]?.value) {
      this.value.value = this.value.options[0].value
    }
  },
}
</script>

<template>
  <div>
    <label
      v-if="label && label !== ''"
      :for="id"
      class="font-weight-bold"
    >
      {{ label }}
    </label>
    <b-form-select
      v-model="value.value"
      :options="value.options"
      :select-size="value.selectSize"
      size="sm"
      class="rounded-0"
    />
  </div>
</template>

<style>
</style>
