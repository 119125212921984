<script>
export default {
  props: {
    value: { type: Object, required: true },
  },
  data: () => ({
    dateFormatOptions: {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    },
  }),
  computed: {
    placeholder() {
      return this.value.placeholder
        ? this.value.placeholder
        : "No date selected"
    },
    message() {
      return this.value.message ? this.value.message : ""
    },
    tip() {
      return this.value.tip ? this.value.tip : ""
    },
    state() {
      return this.value.state ? this.value.state : null
    },
    readOnly() {
      return this.value.readOnly ? this.value.readOnly : false
    },
    disabled() {
      return this.value.disabled ? this.value.disabled : false
    },
    label() {
      return this.value.label ? this.value.label : ""
    },
    id() {
      return this.value.id ? this.value.id : Date.now().toString(36)
    },
  },
  watch: {
    value() {
      this.$emit("input", this.value)
    },
  },
  methods: {
    onChange() {
      this.$emit("change", {
        id: this.id,
        value: this.value.value,
      })
    },
  },
}
</script>

<template>
  <div class="datePicker">
    <label
      v-if="label && label !== ''"
      :for="id"
      class="font-weight-bold"
    >
      {{ label }}
    </label>
    <b-form-datepicker
      v-model="value.value"
      :id="id"
      :state="state"
      :placeholder="placeholder"
      :readonly="readOnly"
      :disabled="disabled"
      class="rounded-0"
      today-button
      reset-button
      close-button
      locale="en"
      :date-format-options="dateFormatOptions"
      :aria-describedby="id + '-live-help ' + id + '-live-feedback'"
      @change="onChange"
    />
    <b-form-invalid-feedback :id="id + '-live-feedback'">
      {{ message }}
    </b-form-invalid-feedback>
    <b-form-text
      v-if="tip"
      :id="id + '-live-help'"
    >
      {{ tip }}
    </b-form-text>
  </div>
</template>

<style>
.datePicker {
  padding-bottom: 0.5rem;
}
</style>
