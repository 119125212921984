<script>
import global from "@/mixins/global.js"
import { mapGetters } from "vuex"

export default {
  components: {
  },
  mixins: [
    global,
  ],
  props: {
    reportData: { default: null, required: true },
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters("global", [
      "getCampaignAcronym",
      "getCampaignSiteCode",
    ]),
    reportType() {
      return this.$route.params.reportType?.toString()
    },
    filteredReportData() {
      const copy = JSON.parse(JSON.stringify(this.reportData))
      if (copy.reportData) {
        copy.reportData = JSON.parse(copy.reportData)
      }
      delete copy.reporter
      delete copy.reportUrl
      delete copy.editReportUrl
      delete copy?.reportData?.actionCodes
      delete copy?.reportData?.additionalEmails
      delete copy?.reportData?.siteFacilityCode
      return copy
    },
  },
  methods: {
    camelCaseToRegularCase(text) {
      return text?.replace(/([A-Z])/g, " $1")?.toUpperCase()
    },
  },
}
</script>

<template>
  <b-modal
    id="maintenance-preview-modal"
    content-class="rounded-0"
    size="lg"
    centered
  >
    <template #modal-header>
      <h3>
        Preview of
        {{ reportType.toUpperCase() }}
        Report
        {{ reportData.reportId }}
      </h3>
    </template>

    <b-row
      v-for="(field, index) in Object.keys(filteredReportData)"
      :key="index"
    >
      <b-col cols="4">
        <label>
          <b>{{ camelCaseToRegularCase(field) }}</b>
        </label>
      </b-col>
      <b-col>
        <div v-if="field === 'reportData'">
          <hr />
          <b-row
            v-for="(subField, subIndex) in Object.keys(filteredReportData[field])"
            :key="subIndex"
          >
            <b-col>
              <label>
                <b>{{ camelCaseToRegularCase(subField) }}</b>
              </label>
            </b-col>
            <b-col>
              {{ filteredReportData[field][subField] }}
            </b-col>
          </b-row>
          <hr />
        </div>
        <div v-else>
          {{ reportData[field] }}
        </div>
      </b-col>
    </b-row>

    <template #modal-footer>
      <b-btn
        class="background-blue"
        squared
        @click="$bvModal.hide('maintenance-preview-modal')"
      >
        Close
      </b-btn>
      <b-btn
        class="background-blue"
        squared
        @click="viewInMaintenanceTools(reportData)"
      >
        View In Maintenance Tools
      </b-btn>
    </template>
  </b-modal>
</template>

<style>
</style>
