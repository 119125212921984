<script>
import store from "@/store/index.js"
import global from "@/mixins/global.js"
import { mapGetters } from "vuex"
import ResultsContainer from "@/components/ResultsContainer"
import Spinner from "@/components/Spinner"

export default {
  components: {
    ResultsContainer,
    Spinner,
  },
  mixins: [
    global,
  ],
  data: () => ({
    loading: false,
    maintenanceReportTypes: [
      {
        id: 1,
        key: "pm",
        reportType: "Preventive Maintenance Reports",
        lastReport: "N/A",
        action: [
          {
            key: "viewReports",
            label: "View Reports",
          },
          {
            key: "createNewReport",
            label: "Create New Report",
          },
        ],
      },
      {
        id: 2,
        key: "cm",
        reportType: "Corrective Maintenance Reports",
        lastReport: "N/A",
        action: [
          {
            key: "viewReports",
            label: "View Reports",
          },
          {
            key: "createNewReport",
            label: "Create New Report",
          },
        ],
      },
    ],
    maintenanceReportTypeFields: [
      { key: "reportType", label: "Report Type" },
      { key: "lastReport", label: "Last Report" },
      { key: "action", label: "Action" },
    ],
  }),
  computed: {
    ...mapGetters("global", [
      "getCampaignAcronym",
      "getCampaignSiteCode",
    ]),
  },
  mounted() {
    this.getLastMaintenaceReports()
  },
  methods: {
    getLastMaintenaceReports() {
      this.loading = true
      this.maintenanceReportTypes.forEach(maintenanceReportType => {
        const payload = {
          reportType: maintenanceReportType.key,
          siteCode: this.getCampaignSiteCode,
          pageInt: 0,
          pageSize: 1,
          sortBy: "reportId",
          sortDir: "DESC",
        }
        store.dispatch("maintenance/getMaintenanceReports", payload).then(response => {
          const lastPmDate = response?.data?.content?.[0]?.reportedOn
          if (lastPmDate) {
            maintenanceReportType.lastReport = this.epochToDate(lastPmDate)
          }
        }).finally(() => {
          this.loading = false
        })
      })
    },
    viewReports(type) {
      this.$router.push({
        path: `/${this.getCampaignAcronym}/maintenance/${type}`,
      })
    },
    createNewReport(reportType) {
      this.createNewMaintenanceReport(reportType, this.getCampaignSiteCode)
    },
  },
}
</script>

<template>
  <b-container class="pt-3">
    <b-row>
      <b-col>
        <h1 class="font-weight-bold">Maintenance Report Types</h1>
      </b-col>
    </b-row>
    <ResultsContainer
      v-if="maintenanceReportTypes.length > 0"
      :rows="maintenanceReportTypes"
      :fields="maintenanceReportTypeFields"
      hide-totals
      @viewReports="viewReports"
      @createNewReport="createNewReport"
    />
    <Spinner
      v-else-if="loading"
      text="Loading reports..."
    />
    <b-card
      v-else
      class="primary-background-light border-0 text-center mt-2"
    >
      <b-card-title class="no-results">
        No report types found.
      </b-card-title>
    </b-card>
  </b-container>
</template>

<style>
</style>
