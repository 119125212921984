<script>
export default {
  props: {
    value: { type: Object, required: true },
  },
  computed: {
    id() {
      return this.value?.id
    },
    url() {
      return this.value?.url
    },
  },
}
</script>

<template>
  <div class="form-image">
    <b-img
      v-if="url"
      :id="id"
      :src="url"
    />
  </div>
</template>

<style scoped>
.form-image {
  margin: 0 auto;
  max-width: 75%;
}
</style>
