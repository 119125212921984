import axios from "axios"
import moment from "moment"
import Config from "/config.js"

const global = {
  namespaced: true,
  state: {
    destination: null,
    processing: false,
    allPeople: [],
    campaign: {
      campaignId: null,
      campaignAcronym: null,
      campaignSiteCode: null,
      campaignCode: null,
    },
    viewsAuthRequired: [
      "ListReports",
      "Report",
      "ReportEdit",
      "MaintenanceReports",
      "MaintenanceReportTypes",
    ],
    fieldSortMap: {
      "fieldCatalog": {
        "reportId": "REPORT_ID",
        "submitter": "PERSON_BY_PERSON_ID__FULL_NAME",
        "reportDate": "REPORT_DATE",
      },
      "cm": {
        "reportId": "reportId",
        "reportedOn": "reportedOn",
        "facilityCode": "reportFacilityCode",
        "instrument": "instrumentCode",
      },
      "pm": {
        "reportId": "reportId",
        "reportedOn": "reportedOn",
        "facilityCode": "reportFacilityCode",
        "instrument": "reportInstrumentClass",
      },
    },
  },
  getters: {
    getDestination: state => {
      return state.destination
    },
    getProcessing: state => {
      return state.processing
    },
    getCampaignId: state => {
      return state.campaign?.campaignId
    },
    getCampaignAcronym: state => {
      const campaignAcronym = state.campaign?.campaignAcronym
      return campaignAcronym ? campaignAcronym.toLowerCase() : campaignAcronym
    },
    getCampaignSiteCode: state => {
      return state.campaign?.campaignSiteCode
    },
    getCampaignCode: state => {
      return state.campaign?.campaignCode
    },
    getChartOptions: state => {
      return state.chartOptions
    },
    getChartFields: state => {
      return state.chartFields
    },
    getFieldSortMap: state => {
      return state.fieldSortMap
    },
  },
  mutations: {
    setDestination: (state, payload) => {
      state.destination = payload
    },
    setProcessing: (state, value) => {
      state.processing = value
    },
    setCampaign: (state, payload) => {
      state.campaign = payload
    },
    clearCampaign: (state) => {
      state.campaign = {
        campaignId: null,
        campaignAcronym: null,
        campaignSiteCode: null,
      }
    },
  },
  actions: {
    setDestination: ({commit}, payload) => {
      commit("setDestination", payload)
    },
    setProcessing: ({commit}, value) => {
      commit("setProcessing", value)
    },
    getCampaigns: ({commit, state, rootState}) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allCatalogCampaigns {
              nodes {
                name
                fcAcronym
                campaignByCampaignId {
                  campaignId
                  campaignCode
                  startDate
                  endDate
                  observatoryInfosByCampaignId {
                    nodes {
                      siteCode
                    }
                  }
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      })
    },
    getCampaignByAcronym: ({commit, state, rootState}, acronym) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allCampaigns(
              filter: {
                statusCode: {
                  notEqualTo: -3
                },
                proposedAcronym: {
                  likeInsensitive: "${acronym}"
                }
              }
            ) {
              nodes {
                observatoryInfosByCampaignId {
                  nodes {
                    siteCode
                    campaignId
                  }
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      })
    },
    getWeatherWidgetData: ({commit, state, rootState}, campaignId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allCatalogCampaigns(
              condition: {
                campaignId: ${campaignId}
              }
            ) {
              nodes {
                weatherLabel
                weatherLink
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      })
    },
    getCampaignOverview: ({commit, state, rootState}, campaignId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allCatalogCampaigns(condition: {campaignId: ${campaignId}}) {
              nodes {
                overview
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      })
    },
    getReportInfo: ({commit, state, rootState}, reportType) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allCatalogReportTypes(
              condition: {
                reportTypeName: "${reportType}"
              }
            ) {
              nodes {
                reportTypeId
                reportTypeName
                reportTypeDesc
                reportSchema
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      })
    },
    searchPeople: ({commit, state, rootState}, payload) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allPeople(
              filter: {
                tsvSearch: {
                  matches: "*${payload}*"
                },
                statusCode: {
                  in: ["A", "F"]
                }
              },
            ) {
              nodes {
                personId
                nameLast
                nameFirst
                email
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      })
    },
    createCatalogReport: ({commit, state, rootState}, catalogReport) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              createCatalogReport(
                input: {
                  catalogReport: {
                    campaignId: ${catalogReport.campaignId},
                    personId: ${catalogReport.personId},
                    reportTypeId: ${catalogReport.reportTypeId},
                    planDetails: """${catalogReport.planDetails}""",
                    shortDescription: """${catalogReport.shortDescription}""",
                    reportDate: """${catalogReport.reportDate}""",
                  },
                }
              ) {
                catalogReport {
                  reportId
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      })
    },
    createCatalogAdditionalFields: ({commit, state, rootState}, additionalFields) => {
      const query = `
        mutation (
          $reportId: Int!,
          $lowLevelClouds: JSON,
          $midLevelClouds: JSON,
          $highLevelClouds: JSON,
          $precipitation: JSON,
          $meteorologicalPhenomena: JSON,
          $weatherNotes: String!,
          $instrumentOperationalNotes: String!,
        ) {
          createCatalogAdditionalField(
            input: {
              catalogAdditionalField: {
                reportId: $reportId,
                lowLevelClouds: $lowLevelClouds,
                midLevelClouds: $midLevelClouds,
                highLevelClouds: $highLevelClouds,
                precipitation: $precipitation,
                meteorologicalPhenomena: $meteorologicalPhenomena,
                weatherNotes: $weatherNotes,
                instrumentOperationalNotes: $instrumentOperationalNotes,
              },
            }
          ) {
            catalogAdditionalField {
              reportId
            }
          }
        }
      `

      const variables = {
        reportId: additionalFields?.reportId,
        lowLevelClouds: additionalFields?.lowLevelClouds,
        midLevelClouds: additionalFields?.midLevelClouds,
        highLevelClouds: additionalFields?.highLevelClouds,
        precipitation: additionalFields?.precipitation,
        meteorologicalPhenomena: additionalFields?.meteorologicalPhenomena,
        weatherNotes: additionalFields?.weatherNotes,
        instrumentOperationalNotes: additionalFields?.instrumentOperationalNotes,
      }

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query,
          variables,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    updateCatalogReport: ({commit, state, rootState}, catalogReport) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              updateCatalogReportByReportId(
                input: {
                  reportId: ${catalogReport.reportId},
                  catalogReportPatch: {
                    planDetails: """${catalogReport.planDetails}""",
                    reportDate: """${catalogReport.reportDate}""",
                    shortDescription: """${catalogReport.shortDescription}""",
                  },
                }
              ) {
                catalogReport {
                  reportId
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      })
    },
    updateCatalogAdditionalFields: ({commit, state, rootState}, additionalFields) => {
      const query = `
        mutation (
          $reportId: Int!,
          $lowLevelClouds: JSON,
          $midLevelClouds: JSON,
          $highLevelClouds: JSON,
          $precipitation: JSON,
          $meteorologicalPhenomena: JSON,
          $weatherNotes: String!,
          $instrumentOperationalNotes: String!,
        ) {
          updateCatalogAdditionalFieldByReportId(
            input: {
              reportId: $reportId,
              catalogAdditionalFieldPatch: {
                lowLevelClouds: $lowLevelClouds,
                midLevelClouds: $midLevelClouds,
                highLevelClouds: $highLevelClouds,
                precipitation: $precipitation,
                meteorologicalPhenomena: $meteorologicalPhenomena,
                weatherNotes: $weatherNotes,
                instrumentOperationalNotes: $instrumentOperationalNotes,
              },
            }
          ) {
            catalogAdditionalField {
              reportId
            }
          }
        }
      `

      const variables = {
        reportId: additionalFields?.reportId,
        lowLevelClouds: additionalFields?.lowLevelClouds,
        midLevelClouds: additionalFields?.midLevelClouds,
        highLevelClouds: additionalFields?.highLevelClouds,
        precipitation: additionalFields?.precipitation,
        meteorologicalPhenomena: additionalFields?.meteorologicalPhenomena,
        weatherNotes: additionalFields?.weatherNotes,
        instrumentOperationalNotes: additionalFields?.instrumentOperationalNotes,
      }

      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query,
          variables,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
      })
    },
    createCatalogAttachment: ({commit, state, rootState}, catalogAttachment) => {
      const formData = new FormData()
      formData.append("file", catalogAttachment.file)

      return axios({
        method: "post",
        url: `${Config.fieldCampaignDashboardFileApi.host}/upload`,
        data: formData,
        params: {
          reportId: catalogAttachment.reportId,
          personId: catalogAttachment.personId,
        },
        withCredentials: true,
      })
    },
    deleteCatalogAttachment: ({commit, state, rootState}, catalogAttachment) => {
      const reportId = catalogAttachment.reportId
      const fileName = catalogAttachment.attachment.file.name
      return axios({
        method: "delete",
        url: `${Config.fieldCampaignDashboardFileApi.host}/deleteFile/${reportId}/${fileName}`,
        withCredentials: true,
      })
    },
    createCatalogComment: ({commit, state, rootState}, catalogComment) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              createCatalogComment(
                input: {
                  catalogComment: {
                    reportId: ${catalogComment.reportId},
                    personId: ${catalogComment.personId},
                    commentDate: "${catalogComment.commentDate}",
                    commentText: "${catalogComment.commentText}",
                  },
                }
              ) {
                catalogComment {
                  commentId
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      })
    },
    createCatalogEmail: ({commit, state, rootState}, catalogEmail) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              createCatalogEmail(
                input: {
                  catalogEmail: {
                    reportId: ${catalogEmail.reportId},
                    personId: ${catalogEmail.personId},
                  },
                }
              ) {
                catalogEmail {
                  emailId
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      })
    },
    deleteCatalogEmail: ({commit, state, rootState}, emailId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `
            mutation {
              deleteCatalogEmailByEmailId(
                input: {
                  emailId: ${emailId},
                }
              ) {
                catalogEmail {
                  emailId
                }
              }
            }
            `,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      })
    },
    getContacts: ({commit, state, rootState}, campaignId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allCatalogContacts(
              condition: {
                campaignId: ${campaignId}
              }
            ) {
              nodes {
                affiliation
                campaignId
                contactOrder
                contactSubtype
                contactType
                email
                nameFirst
                nameLast
              }
            }
          }`,
        },
        withCredentials: false,
      })
    },
    getCharts: ({commit, state, rootState}, campaignId) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allCatalogCharts(
              condition: {
                campaignId: ${campaignId}
              }
            ) {
              edges {
                node {
                  chartInputSchema
                  chartInputType
                  chartCode
                  chartLabel
                  chartUrl
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        withCredentials: false,
      })
    },
    getChart: ({commit, state, rootState}, chartCode) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allCatalogCharts(
              condition: {
                chartCode: "${chartCode}"
              }
            ) {
              edges {
                node {
                  chartInputSchema
                  chartInputType
                  chartLabel
                  chartUrl
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        withCredentials: false,
      })
    },
    getAvailableReportDates: ({commit, state, rootState}, payload) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allCatalogReports(
              orderBy: REPORT_ID_DESC,
              first: 1,
              filter: {
                campaignId: {
                  equalTo: ${payload.campaignId}
                },
                reportTypeId: {
                  equalTo: ${payload.reportTypeId}
                }
              }
            ) {
              nodes {
                reportDate
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        withCredentials: false,
      })
    },
    getReportTypes: ({rootState}, payload) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allCatalogCampaignReportTypes(
              condition: {campaignId: ${payload}}
              orderBy: REPORT_TYPE_ID_ASC
            ) {
              edges {
                node {
                  catalogReportTypeByReportTypeId {
                    reportTypeId
                    reportTypeName
                    reportTypeDesc
                    catalogReportsByReportTypeId(
                      last: 1
                      condition: {campaignId: ${payload}}
                    ) {
                      nodes {
                        reportDate
                      }
                    }
                  }
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      })
    },
    getReports: ({commit, state, rootState}, payload) => {
      return axios({
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allCatalogReportTypes(condition: {reportTypeName: "${payload.reportType}"}) {
              nodes {
                reportTypeDesc
                catalogReportsByReportTypeId(
                  condition: {campaignId: ${payload.campaignId}}
                  orderBy: ${payload.orderBy}
                  offset: ${(payload.pageNumber - 1) * payload.pageSize}
                  first: ${payload.pageSize}
                  filter: {
                    recordText: {
                      includesInsensitive: """${payload.filter}"""
                    }
                  }
                ) {
                  nodes {
                    reportId
                    reportDate
                    reportUpdated
                    shortDescription
                    personByPersonId {
                      nameFirst
                      nameLast
                    }
                    catalogReportTypeByReportTypeId {
                      reportTypeDesc
                    }
                  }
                  pageInfo {
                    endCursor
                    hasNextPage
                    hasPreviousPage
                    startCursor
                  }
                  totalCount
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          "authorization": `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      })
    },
    getReport: ({commit, state, rootState}, reportId) => {
      const data = {
        method: "post",
        url: Config.graphql.host,
        data: {
          query: `{
            allCatalogReports(condition: {reportId: ${reportId}}) {
              nodes {
                reportId
                catalogAttachmentsByReportId {
                  nodes {
                    attachmentId
                    attachmentName
                    attachmentPath
                    personId
                  }
                }
                catalogCommentsByReportId {
                  nodes {
                    commentId
                    commentText
                    personByPersonId {
                      personId
                      nameFirst
                      nameLast
                    }
                    commentDate
                  }
                }
                catalogEmailsByReportId {
                  nodes {
                    personByPersonId {
                      nameFirst
                      nameLast
                      email
                      personId
                    }
                    emailId
                  }
                }
                planDetails
                shortDescription
                reportDate
                reportUpdated
                personByPersonId {
                  nameFirst
                  nameLast
                  personId
                }
                catalogReportTypeByReportTypeId {
                  reportTypeId
                  reportTypeName
                  reportTypeDesc
                  reportSchema
                }
                campaignByCampaignId {
                  campaignCode
                }
                catalogAdditionalFieldByReportId {
                  lowLevelClouds
                  midLevelClouds
                  highLevelClouds
                  precipitation
                  meteorologicalPhenomena
                  weatherNotes
                  instrumentOperationalNotes
                }
              }
            }
          }`,
        },
        headers: {
          "Content-Type": "application/json; charset=utf-8",
          authorization: `Bearer ${rootState.GraphQL.graphqlTicket}`,
        },
        withCredentials: false,
      }
      return axios(data)
    },
    // TODO - improve this
    sendEmailNotifications: ({dispatch, state, rootState, getters}, payload) => {
      const reportId = payload.reportId
      dispatch("getReport", reportId).then(response => {
        const nodes = response?.data?.data?.allCatalogReports?.nodes
        if (nodes && Array.isArray(nodes) && nodes.length > 0) {
          const report = nodes[0]
          const campaign = report?.campaignByCampaignId?.campaignCode.toUpperCase()
          const reportType = report?.catalogReportTypeByReportTypeId?.reportTypeDesc
          const submitter = `${report?.personByPersonId?.nameFirst} ${report?.personByPersonId?.nameLast}`
          const lastUpdatedBy = `${report?.reportUpdated?.nameFirst} ${report?.reportUpdated?.nameLast}`
          const submissionDate = moment.utc(report?.reportEntered?.datetime).format("YYYY-MM-DD HH:mm:ss")
          const lastUpdatedDate = moment.utc(report?.reportUpdated?.datetime).format("YYYY-MM-DD HH:mm:ss")
          const planDetails = report.planDetails
          const shortDescription = report.shortDescription

          const submitterPersonId = report?.reportEntered?.personId
          const updaterPersonId = report?.reportUpdated?.personId
          const additionalRecipientsPersonIds = payload.formattedReport.email.map(email => email.value)

          const recipientPersonIds = []
          if (submitterPersonId) recipientPersonIds.push(submitterPersonId)
          if (updaterPersonId && updaterPersonId !== submitterPersonId) recipientPersonIds.push(updaterPersonId)
          if (additionalRecipientsPersonIds && Array.isArray(additionalRecipientsPersonIds) && additionalRecipientsPersonIds.length > 0) {
            additionalRecipientsPersonIds.forEach(additionalRecipientPersonId => {
              if (!recipientPersonIds.includes(additionalRecipientPersonId)) {
                recipientPersonIds.push(additionalRecipientPersonId)
              }
            })
          }

          const reportUrl = `${Config.app.host}/#/${getters.getCampaignAcronym}/report/${reportId}`

          const attachments = report?.catalogAttachmentsByReportId?.nodes
          attachments.forEach(attachment => {
            const attachmentName = attachment.attachmentName.replace(/ /g, "_").replace(/#/g, "")
            const attachmentUrl = `${reportUrl}?attachment=${attachmentName}`
            attachment.attachmentPath = attachmentUrl
          })

          const subject = `ARM Field Campaign Dashboard: ${campaign} Report submitted`

          const title = "ARM Field Campaign Dashboard"

          let text = `Campaign: ${campaign}<br />`
          text += `Report Type: ${reportType}<br />`
          text += `Report ID: ${reportId}<br />`
          text += `Submitter: ${submitter}<br />`
          text += `Submission date: ${submissionDate}<br />`

          if (!moment.utc(submissionDate).isSame(moment.utc(lastUpdatedDate))) {
            text += `Last Updated By: ${lastUpdatedBy}<br />`
            text += `Last Updated Date: ${lastUpdatedDate}<br />`
          }

          text += `<br />Plan Details:<br />${planDetails}<br />`
          text += `<br />Short Description:<br />${shortDescription}<br />`

          if (attachments && Array.isArray(attachments) && attachments.length > 0) {
            text += "Attachments:<br />"
            attachments.forEach(attachment => {
              text += `* <a href="${attachment.attachmentPath}">${attachment.attachmentName}</a><br />`
            })
          }

          text += `<br />To view or edit the report, click <a href="${reportUrl}">here</a><br />`
          text += `<br />If you have any questions, please <a href="mailto:adc@arm.gov">contact us</a>`

          text += "<br /><br />*** Automated E-Mail - Please do not reply ***"

          const simpleEmailRequest = {
            recipientPersonIds,
            senderName: "ARM Field Campaigns",
            senderEmail: "noreply@ornl.gov",
            subject,
            title,
            text,
            recaptchaResponse: payload.recaptchaResponse,
          }

          return axios({
            method: "post",
            url: `${Config.notification.baseURL}/sendHtmlEmail`,
            data: simpleEmailRequest,
          })
        }
      })
    },
    setCampaign ({commit}, campaign) {
      commit("setCampaign", campaign)
    },
    clearCampaign ({commit}) {
      commit("clearCampaign")
    },
  },
}

export default global
