<script>
export default {
  props: {
    text: { default: "", type: String },
  },
}
</script>

<template>
  <div class="text-center">
    <div class="spinnerWrapper">
      <div>
        {{ text }}
      </div>
      <b-spinner />
    </div>
  </div>
</template>

<style>
.spinnerWrapper {
  font-size: 1.4em;
  opacity: 0.75;
  color: white;
  background-color: #194173;
  padding: 1em;
  margin-top: 1em;
  display: inline-block;
}
</style>
