import Vue from "vue"
import VueRouter from "vue-router"
import Dashboard from "@/views/Dashboard"
import Reports from "@/views/Reports"
import ListReports from "@/views/ListReports"
import Report from "@/views/Report"
import Overview from "@/views/Overview"
import Maps from "@/views/Maps"
import MaintenanceReportTypes from "@/views/MaintenanceReportTypes"
import MaintenanceReports from "@/views/MaintenanceReports"
import Contacts from "@/views/Contacts"
import DataPlots from "@/views/DataPlots"
import Charts from "@/views/Charts"
import Chart from "@/views/Chart"
import Predictions from "@/views/Predictions"

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/predictions",
    component: Predictions,
  },
  {
    path: "/:campaign",
    redirect: "/:campaign/overview",
  },
  {
    path: "/:campaign/overview",
    name: "Overview",
    component: Overview,
  },
  {
    path: "/:campaign/reports",
    name: "Reports",
    component: Reports,
  },
  {
    path: "/:campaign/report/:reportType/list",
    name: "ListReports",
    component: ListReports,
  },
  {
    path: "/:campaign/report/:reportType/new",
    name: "Report",
    component: Report,
  },
  {
    path: "/:campaign/report/:reportId",
    name: "ReportEdit",
    component: Report,
  },
  {
    path: "/:campaign/maps",
    name: "Maps",
    component: Maps,
  },
  {
    path: "/:campaign/maintenance",
    name: "MaintenanceReportTypes",
    component: MaintenanceReportTypes,
  },
  {
    path: "/:campaign/maintenance/:reportType",
    name: "MaintenanceReports",
    component: MaintenanceReports,
  },
  {
    path: "/:campaign/contacts",
    name: "Contacts",
    component: Contacts,
  },
  {
    path: "/:campaign/plots",
    name: "DataPlots",
    component: DataPlots,
  },
  {
    path: "/:campaign/charts",
    name: "Charts",
    component: Charts,
  },
  {
    path: "/:campaign/chart/:chartCode",
    name: "Chart",
    component: Chart,
  },
  // Default route if none specified.
  {
    path: "*",
    redirect: "/",
  },
]

const router = new VueRouter({
  routes,
})

export default router
